.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MTableHead-root{

background-color: '#f7f7f7' !important;
}

.raphael-group-48-pie-label{

  margin-left: '-50' !important;
}


.MTableHeader-header-297{

  background-color: #f7f7f7 !important;
}

.MTableHeader-header-429{
  background-color: #f7f7f7 !important;
}


.MTableHeader-header-400{
  background-color: #f7f7f7 !important;
}

.MTableHeader-header-703{
  background-color: #f7f7f7 !important;
}

.MTableHeader-header-977{
  background-color: #f7f7f7 !important;
}
.MTableHeader-header-1251{
  background-color: #f7f7f7 !important;
}

.raphael-group-8-background{
  border-radius: '8px' !important;
}

.raphael-group-kmLvJVGR{

  display: none !important;
  font-size: 0px !important;
}

.MuiIconButton-root:hover{
  background-color: transparent !important;
  color: #9e9c9c !important;
}


.MTableHeader-header-469{
  background-color: #fbfbfb !important;
}


